import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=637ab284&scoped=true&"
import script from "./detail.vue?vue&type=script&setup=true&lang=ts&"
export * from "./detail.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./detail.vue?vue&type=style&index=0&id=637ab284&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637ab284",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectWithValidate: require('/home/runner/work/contrea-api/contrea-api/admin/components/SelectWithValidate.vue').default,VideoPlayer: require('/home/runner/work/contrea-api/contrea-api/admin/components/VideoPlayer.vue').default})
